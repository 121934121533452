import { render, staticRenderFns } from "./IconStarCircle.vue?vue&type=template&id=534b5073&scoped=true&"
import script from "./IconStarCircle.vue?vue&type=script&lang=ts&"
export * from "./IconStarCircle.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "534b5073",
  null
  
)

export default component.exports